import React, { useRef, useState } from "react";
import { Collapse, Divider, Checkbox, Tooltip, Tag, } from 'antd';
import { MinusCircleOutlined, DownOutlined } from '@ant-design/icons';
import BrowseFilterSub from "./BrowseFilterSub";
import conditional from "../utilities/conditional";
import moment from "moment";

export default function BrowseFilters(props) {


    const subjectsRef = useRef();
    const subSubjectsRef = useRef();
    const pubdateRef = useRef();
    const formatRef = useRef();
    const ageRef = useRef();
    const languageRef = useRef();
    const catRef = useRef();


    const getRef = (_key) => {

        switch (_key) {

            case "c":
                return catRef;

            case "s":
                return subjectsRef;

            case "x":
                return subSubjectsRef;

            case "d":
                return pubdateRef;

            case "f":
                return formatRef;

            case "a":
                return ageRef;

            case "g":
                return languageRef;
        }

    }


    const tips = window.sitesettings.tool_tips;
    const { 
        filters = {}, 
        searchFilters = {} 
    } = props;
    const negateCategory = (_filterID, _filterCat) => {

        if (!checkNeg(_filterID)) {
            // add 
            if (props.searchFilters[_filterCat].includes(_filterID)) {
                // need to change to minus
                changeFilter(_filterID, _filterCat, ("-" + _filterID))


            } else {
                // neet to add
                addFilter(("-" + _filterID), _filterCat);
            }

        } else {
            if (props.searchFilters[_filterCat].includes("-" + _filterID)) {
                // exists - need to remove
                removeFilter(("-" + _filterID), _filterCat);
            }
        }

    }




    const toggleFilter = (e, _filter) => {
        (e.target.checked) ? addFilter(e.target.id, _filter, e) : removeFilter(e.target.id, _filter);
    }


    const mutualExclude = (_filter, _item) => {

        let obj = false;
        let key = "";
        let exclude_number = "";

        // Find matching key and group
        Object.keys(filters).forEach(element => {
            filters[element].forEach(itm => {
                if (itm.k === _item) {
                    if (itm.hasOwnProperty("m")) {
                        key = element;
                        obj = itm;
                        exclude_number = itm.m;
                    }
                }
            });
        });

        // Key is flagged as mutually exclusive, need to find any currently active mutually exclusive keys within the group
        if (obj) {
            let update = [_item]
            filters[key].forEach(itm => {
                // only check non-mutual
                if (!itm.hasOwnProperty("m")) {
                    // push if it exists
                    if (props.searchFilters[_filter].includes(itm.k)) {
                        update.push(itm.k);
                    }
                } else {
                    if (itm.m !== exclude_number) {

                        if (props.searchFilters[_filter].includes(itm.k)) {
                            update.push(itm.k);
                        }

                    }
                }
            })

            if (_filter === "s") {
                props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: update, "j": [] });
            } else {
                props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: update });
            }

            // it's been set - return true to not overwrite
            return true;
        }

        // None found
        return false;

    }


    const addFilter = (e, _filter, event) => {

        if (props.searchFilters[_filter].includes(("-" + e))) {
            changeFilter(("-" + e), _filter, e);
        } else {
            if (!props.searchFilters[_filter].includes(e)) {
                if (event.ctrlKey) {

                    if (_filter === "s") {
                        // clear out the refined subjects
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: [...props.searchFilters[_filter], e], "j": [] });
                    } else {
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: [...props.searchFilters[_filter], e] });
                    }

                    return;
                }
                if (!mutualExclude(_filter, e)) {

                    if (_filter === "s") {
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: [...props.searchFilters[_filter], e], "j": [] });
                    } else {
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: [...props.searchFilters[_filter], e] });
                    }
                }
            }
        }

    }

    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    // let start_open = false; 
    // currentFilters.forEach(element => {
    //     if(isNumeric(element.substr(element.length-3,3))){
    //         start_open = true; 
    //     }
    // });

    const hasNumber= (_str) => {
        return /\d/.test(_str);
      }


    const removeFilter = (e, _filter) => {


     
        if (props.searchFilters[_filter].includes(e)) {


            props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: props.searchFilters[_filter].filter((item) => { return item !== e }) })

            if (_filter === "s") {



                if (props.searchFilters.s.length > 1 && isNumeric(props.searchFilters.s[1].substr(props.searchFilters.s[1].length - 3, 3))) {

                    if (isNumeric(e.substr(e.length - 3, 3))) {
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, "j": [], [_filter]: props.searchFilters[_filter].filter((item) => { return item !== e }) })
                    } else {
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, "s": [] })
                    }

                    // remaining are sub, remove
                    // props.setSearchFilters({ ...props.searchFilters, "o": 0, ["s"]: []})
                } else {
                    props.setSearchFilters({ ...props.searchFilters, "o": 0, "j": [], [_filter]: props.searchFilters[_filter].filter((item) => { return item !== e }) })
                }


            } else {

                // Deselecting category parent
                if (_filter === "c") {
                    if(e.indexOf("-") <0){
                        // is parent
                        props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: props.searchFilters[_filter].filter((item) => { return !item.includes(e)}) })
                        return;
                    }
                }

                props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: props.searchFilters[_filter].filter((item) => { return item !== e }) })
            }

        }
    }

    const changeFilter = (e, _filter, r) => {
        if (props.searchFilters[_filter].includes(e)) {

            if (_filter === "s") {
                props.setSearchFilters({ ...props.searchFilters, "o": 0, "j": [], [_filter]: [...props.searchFilters[_filter].filter((item) => { return item !== e }), r] })
            } else {
                props.setSearchFilters({ ...props.searchFilters, "o": 0, [_filter]: [...props.searchFilters[_filter].filter((item) => { return item !== e }), r] })
            }


        }
    }

    const formatCount = (_count) => {

        if (_count === "-1") {
            return "";
        }

        if (_count.length > 3) {
            return _count.substring(0, _count.length - 3) + "k";
        }
        return _count.toString()
    }

    // [CHECK IF NEEDED]
    const slugify = (string) => {
        return encodeURI(string).replace(/"%20"/g, "~");
    }

    const checkNeg = (_filter) => {

        if (!props.filters.hasOwnProperty("excludes")) {
            return false;
        }

        if (props.filters.excludes.includes(_filter)) {
            return true;
        } else {
            return false;
        }

    }

    const displayItem = (_item, _index, _filter, _indent = false, _arrow = false) => {

      
        if (_item.k === "--") {
            return (<div style={{"width" : "245px", "overflow" : "hidden", "textOverflow" : "inherit"}}><Divider plain style={{"margin" : "0px 0px", "marginBottom" : "8px", "color" : "#eee"}} key={_index} dashed ><small style={{"color" : "#666"}}>{(_item.hasOwnProperty("e")) ? _item.e : ""}</small></Divider></div>)
        }
        let isChecked = searchFilters[_filter].includes(slugify(_item.k));
        return (<div className={(_indent) ? "indent" : ""} key={_item.k} style={{ "paddingBottom": "10px" }} >
            <Checkbox aria-label={slugify(_item.k)} disabled={(_item.c === "0")} checked={isChecked} id={slugify(_item.k)} onClick={(e) => toggleFilter(e, _filter)} />&nbsp;&nbsp;{(_item.hasOwnProperty('n') && <MinusCircleOutlined onClick={(e) => negateCategory(_item.k, _filter)} style={{ "color": (checkNeg(_item.k) ? "#e00" : "#999") }} />)}
            <Tooltip mouseEnterDelay={0.5} title={(tips[_item.k]) ? (tips[_item.k]) : _item.e}>
                <label style={{ "fontWeight": (isChecked) ? "bold" : "normal", "display": "inline-block", "maxWidth": "200px", "whiteSpace" : "nowrap", "textOverflow" : "ellipsis", "overflowX" : "clip", "overflowY" : "inherit",  "lineHeight" : "11px", "color": (_item.c === "0") ? "#666" : "inherit", "cursor" : "pointer" }}>{(_item.e.substr(0, 2) === "__") ? _item.e.substr(3, _item.e.length) : _item.e} {(_arrow && <DownOutlined className="flipArrow" style={{"fontSize" : "10px"}} />)}</label>
            </Tooltip>{(_item.hasOwnProperty("c") && <span className="count">{formatCount(_item.c.toString())}</span>)}
        </div>)
    }




    const executeScroll = (e) => {


        setOpened(e);


        let space = 32.34;
        if (subjectsRef) {
            if (!e.includes("s")) {
                let index = filters.bisac36.findIndex((itm) => itm.s === true);
                if(index===-1){return}
                let space_index = filters.bisac36.findIndex((itm) => itm.k === "--");
                let gap = 20;
                if(space_index < index){
                    gap += -23;
                }
                setTimeout(() => {
                    subjectsRef.current.scrollTop = parseInt((space * index) + gap);
                }, 100)
            }
        }

        if (subSubjectsRef) {
            if (e.includes("x")) {
                if(!filters.hasOwnProperty("bisac36_topics")){
                    return; 
                }
                let index = filters.bisac36_topics.findIndex((itm) => itm.s === true);
                setTimeout(() => {
                    subSubjectsRef.current.scrollTop = parseInt(space * index);
                }, 100)
            }
        }

        if (pubdateRef) {
            if (e.includes("d")) {
                let index = filters.pubdate.findIndex((itm) => itm.s === true);
                setTimeout(() => {
                    pubdateRef.current.scrollTop = parseInt(space * index);
                }, 100)
            }
        }

        if (formatRef) {
            if (e.includes("f")) {
                let index = filters.binding.findIndex((itm) => itm.s === true);
                setTimeout(() => {
                    formatRef.current.scrollTop = parseInt(space * index);
                }, 100)
            }
        }

        if (ageRef) {
            if (e.includes("a")) {
                let index = filters.audience.findIndex((itm) => itm.s === true);
                setTimeout(() => {
                    ageRef.current.scrollTop = parseInt(space * index);
                }, 100)
            }
        }

        if (languageRef) {
            if (e.includes("g")) {
                let index = filters.language.findIndex((itm) => itm.s === true);
                setTimeout(() => {
                    languageRef.current.scrollTop = parseInt(space * index);
                }, 100)
            }
        }



        // if (pubDateScrollPosition) {

        //     console.log(pubDateScrollPosition)
        //     let space = 32.34;
        //     setTimeout(() => {
        //         pubDateScrollPosition.current.scrollTop = parseInt(space * getPosition());
        //     }, 100)
        // }


    }
    
    const getToolTip = (_key) =>{

        switch(_key){
            case "s":
                return <>Filter by Subject <br /><small>(the most popular listed here first, followed by all subjects in alphabetical order)</small></>;
            case "g":
                return <>Filter by Language <br /><small>(the most popular listed here first, followed by all languages in alphabetical order)</small></>;
            default:
                return ""
        }
    }


    const displayWrap = (_subjects, _key, _label, _maxheight, _filter, _sort = false, hide_label = false) => {
        if (_subjects.length === 0) {
            return (<></>);
        }

        let active = _subjects.filter(item => (searchFilters[_filter].includes(item.k) && (_label !== "")));
        let uniqueActive = [...new Map(active.map(item =>[item["e"], item])).values()];

        return (
            <Collapse.Panel aria-label={(_label) ? _label : "New"}  forceRender={true} className={(_filter === "r") ? "hide-header" : (_filter === "b") ? "" : ""} style={{ "marginTop": (_label) ? "0" : "-15px" }} collapsible={(hide_label) ? "disabled" : ""} showArrow={!(hide_label)}

                header={
                    <><Tooltip title={getToolTip(_key)}>{_label}</Tooltip>

                        {((!opened.includes(_key) && _subjects.filter(item => (searchFilters[_filter].includes(item.k) && (_label !== ""))).length > 0) &&
                            <div style={{ "fontSize": "12px", "marginTop": "10px" }}>

                                
                                {uniqueActive.map((itm) => {
                                    return <Tag style={{"maxWidth" : "250px", "overflow" : "hidden", "textOverflow" : "ellipsis"}} key={itm.k}  onClose={(e) => { e.preventDefault(); e.stopPropagation(); removeFilter(itm.k, _filter)}}  onClick={(e) => { e.preventDefault(); e.stopPropagation(); removeFilter(itm.k, _filter)}}  closable>{itm.e}</Tag>
                                })}
                            </div>
                        )}
                    </>
                }
                key={_key}>
                <div ref={getRef(_key)} style={{ "maxHeight": _maxheight, "overflowY": "auto" }} >
                    <conditional.true value={(_label === "Subjects")}>
                        <div style={{ "textAlign": "left", "marginTop": "0px", "paddingLeft": "3px" }}>
                            {/* <Tabs style={{ "marginLeft": "-3px" }} onChange={(e) => setShowBooks((e === "1"))} activeKey={(showBooks) ? "1" : "2"} type="card" size="small">
                                <Tabs.TabPane tab={<small>Books</small>} key="1"></Tabs.TabPane>
                                <Tabs.TabPane tab={<small>Other</small>} key="2"></Tabs.TabPane>
                            </Tabs> */}

                            {/* <Space align="center">
                                <Switch checked={showBooks} style={{ "width": "55px" }} onChange={(_v) => toggleBookFilter("books", _v)} checkedChildren={<small>&nbsp;Books</small>} unCheckedChildren={<small>&nbsp;Books&nbsp;</small>} size="small" />
                                <Switch checked={showNonBooks} style={{ "width": "86px" }} onChange={(_v) => toggleBookFilter("non-books", _v)} checkedChildren={<small>&nbsp;Non-Books</small>} unCheckedChildren={<small>&nbsp;Non-Books&nbsp;</small>} size="small" />
                            </Space> */}
                        </div>
                        {/* <Divider style={{ "margin": "5px 0px", "marginTop": "10px" }} dashed /> */}
                    </conditional.true>
                    {_subjects.map((_item, _index) => {
                        if (_item.hasOwnProperty("subfilter")) {
                            return (
                                <BrowseFilterSub scroll={(_subjects.length === (_index+1))} parent_ref={getRef(_key)} currentFilters={(searchFilters[_filter])} index={_index} heading={displayItem(_item, _index, _filter, false, (_item.hasOwnProperty("subfilter") && _item.subfilter.length > 0))}>
                                    {(_item.hasOwnProperty("subfilter") && <div> {_item.subfilter.map((_sub, _subindex) => displayItem(_sub, _subindex, _filter, true))}</div>)}
                                </BrowseFilterSub>
                            )
                        } else {

                            return (
                                <div key={_index}>
                                    {displayItem(_item, _index, _filter, false, (_item.hasOwnProperty("subfilter")))}
                                </div>
                            )

                        }


                    })}
                </div>
            </Collapse.Panel>
        )
    }

    const getOpened = () => {

        let ret = ["r", "b"];
        Object.keys(searchFilters).forEach((key) => {
            if (Array.isArray(searchFilters[key]) && searchFilters[key].length > 0) {
                ret.push(key);
            }
        })

        if (!ret.includes("s")) {
            ret.push("s");
        }

        if (!ret.includes("x")) {
            ret.push("x");
        }

        return ret;
    }

    const [opened, setOpened] = useState(getOpened());


    // useEffect(() =>{
    //     executeScroll(getOpened());
    // }, [])

    const findRefinedLabel = () => {

        let lbl = "Topics";

        if (searchFilters.s.length > 0) {
            // find
            for (let i = 0; i < filters.bisac36.length; i++) {
                if (filters.bisac36[i].k === searchFilters.s[0]) {
                    lbl = filters.bisac36[i].e;
                }
            }
            return lbl + " Topics";

        } else {
            return lbl;
        }

    }



    const displayFilters = () => {

        return (
            <>
                <div style={{"marginTop" : "12px"}} className="sidebar">
                    <Collapse
                        ghost
                        className="browsefilters"
                        expandIconPosition="right"
                        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                        bordered={true}
                        onChange={(e, f) => executeScroll(e)}
                        defaultActiveKey={getOpened()}
                    >
                        {(filters.hasOwnProperty("onhand_onorder") && filters.onhand_onorder != null && displayWrap(filters.onhand_onorder, "r", "", "325px", "r", false, true))}
                        {(filters.hasOwnProperty("status") && filters.status != null && displayWrap(filters.status, "b", "", "440px", "b", false, true))}
                        {(filters.hasOwnProperty("bisac36") && filters.bisac36 != null && displayWrap(filters.bisac36, "s", "Subjects", "325px", "s", true))}
                        {/* {(filters.hasOwnProperty("bisac36_and1") && filters.bisac36_and1 != null && displayWrap(filters.bisac36_and1, "j", findRefinedLabel(), "300px", "j"))} */}

                        {(filters.hasOwnProperty("bisac36_topics") && filters.bisac36_topics != null && displayWrap(filters.bisac36_topics, "x", findRefinedLabel(), "325px", "s"))}

                        {(filters.hasOwnProperty("catcat2") && filters.catcat2 != null && displayWrap(filters.catcat2, "c", window.sitesettings.bm_class_name, "300px", "c"))}


                        {/* {(filters.hasOwnProperty("subj1") && filters.subj1 != null && displayWrap(filters.subj1.filter((cat) => { return cat.e.substr(0, 2) !== "__" }), "s", "Subjects", "300px", "s", true))}
                        {(filters.hasOwnProperty("subj1") && filters.subj1 != null && displayWrap(filters.subj1.filter((cat) => { return cat.e.substr(0, 2) === "__" }), "n", "Non Books", "300px", "n", true))} */}
                        {(filters.hasOwnProperty("binding") && filters.binding != null && displayWrap(filters.binding, "f", "Format", "325px", "f"))}
                        {(filters.hasOwnProperty("pubdate") && filters.pubdate != null && displayWrap(filters.pubdate, "d", "Date Published", "325px", "d"))}
                        {(filters.hasOwnProperty("audience") && filters.audience != null && displayWrap(filters.audience, "a", "Age", "325px", "a"))}
                        {(filters.hasOwnProperty("language") && filters.language != null && displayWrap(filters.language, "g", "Language", "325px", "g"))}
                    </Collapse>
                </div>
            </>
        );

    }

  

    return displayFilters();

}