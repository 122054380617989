import React, { useEffect, useState } from "react";
import { useSession } from "../util/Session";
import { existsInWishlist, existsInCart, processCartOptions } from "../util/Utils";
import { Divider, Button, Space, Typography, Card, Modal, Menu, Dropdown, Tooltip, message, Alert } from 'antd';
import IncrementFormatter from "./formatters/IncrementFormatter";
import { apiCall } from "../components/utilities/Api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import conditional from "./utilities/conditional";
import AddToList from "./components/lists/AddToList";
import { GiftOutlined } from '@ant-design/icons';
import { StarIcon, StarStrokeIcon } from "../util/Icons";
import AlsoAvailable from "./components/item/AlsoAvailable";
import GiftRegistryAdd from "./components/giftregistry/GiftRegistryAdd";
import { drawUSCanPrice } from "../util/Utils";
import DrawShelfLocation from "./components/DrawShelfLocation";
import BMAlert from "../util/BMAlert";
function AddToCart(props) {



    const location = useLocation();

    const getReg = () => {
        if (location.search.substring(0, 10) === "?registry=") {
            return location.search.replace("?registry=", "");
        }
        return ""
    }

    const { showLists = true, gift = false, gift_registry_id = "", impulse = false, tempSession = {}, setTempSession = () => { } } = props;
    const [registry, setRegistry] = useState(getReg());
    const [registryDetails, setRegistryDetails] = useState({});




    const getGiftRegistry = () => {

        if (registry) {
            apiCall("giftregistry/get", { gift_registry_id: registry }, (_status, _result) => {
                if (_status) {
                    if (_result.gift_registry_id !== registry) {
                        setRegistry("");
                        setRegistryDetails(_result);
                    }
                } else {
                    setRegistry("");
                }
            })
        }

    }

    useEffect(getGiftRegistry, [])


    const data = props.data;
    const history = useHistory();





    const [values, setValues] = useState({});
    const [context, setContext] = useSession();
    const [transitionItem, setTransitionItem] = useState("");
    const [loading, setLoading] = useState(false);


    const onAddToItemToCart = (_status, _result) => {
        setLoading(false);
        if (_status) {
            if (impulse) {
                setTempSession({ ...tempSession, "cart": _result })
            } else {
                setContext({ ...context, "cart": _result })
            }
        } else {
            message.error(_result.error);
        }
    }

    const showConfirm = (e, _eisbn, _item, _quantity, _onhand) => {
        let m = Modal.warn({
            icon: null,
            centered: true,
            title: "Add to cart",
            width: "auto",
            style: { "padding": "0px" },
            content: <>
                <p>We only have <strong>{_item.onhand}</strong> in stock</p>
                <Space size={5} direction="vertical">
                    <Button onClick={() => returnItem(true)}>I'll take the {_item.onhand}</Button>
                    {/* <conditional.true value={context.logged_in}> */}
                    {(_item.is_extra_orderable && <Button onClick={() => returnItem(false)}>I'll take the {_item.onhand} but want the rest ordered</Button>)}
                    {/* </conditional.true> */}
                </Space>
            </>,
            okText: "Cancel",
        });
        const returnItem = (_onlyonhand) => {
            m.destroy();
            addToItemToCart(null, _eisbn, _item, (_onlyonhand) ? _item.onhand : _quantity, true)
        }
    }


    const removeItemFromCart = (_found) => {

        apiCall("cart/removeItem", { cart_id: _found.cart_id }, (_status, _result) => {
            if (_status) {
                setTempSession({ ...tempSession, "cart": _result })
            } else {
                message.error(_result.error)
            }
        })

    }

    const addToItemToCart = (e, _eisbn, _item, _quantity, force = false) => {



        let _code = _item.code;
        if (!_quantity) {
            if (values.hasOwnProperty(_eisbn + _code)) {
                _quantity = values[_eisbn + _code]
            } else {
                _quantity = 1;
            }
        }

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }


        if (!force && (parseInt(_item.onhand) > 0) && (parseInt(_quantity) > parseInt(_item.onhand))) {
            showConfirm(e, _eisbn, _item, _quantity, data.available.onhand);
            return;
        }

        setTransitionItem(_code);
        setLoading(true);

        let obj = {
            eisbn: _eisbn,
            condition: _code,
            quantity: _quantity
        }

        if (gift_registry_id) {
            obj.gift_registry_id = gift_registry_id;
        }

        if (registry) {
            obj.gift_registry_id = registry;
        }



        if (_item.is_large_print) {

            Modal.confirm({
                okText: "Add to cart", centered: true, title: "Please confirm", content: <>A <b>Large Print</b> edition has been selected.</>, onCancel: () => {
                    setLoading(false);
                }, onOk: () => {
                    apiCall("cart/add", obj, onAddToItemToCart);
                }
            })

        } else {

            apiCall("cart/add", obj, onAddToItemToCart)
        }




    }

    const linkToPath = (e, path) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(path);
    }

    const onFetchWishlist = (_status, _result) => {
        if (_status) {
            setContext({ ...context, "wishlist": _result })
        }
    }

    const onAddToWishlist = (_status, _result) => {
        if (_status) {
            setContext(_result);
            //apiCall("titlelist/getItems", { list_id : context.wishlist_id }, onFetchWishlist);
        }
    }
    const addToWishlist = (e, _eisbn) => {
        e.preventDefault();
        e.stopPropagation();
        // apiCall("wishlist/add", { eisbn: _eisbn }, onAddToWishlist)

        if (context.hasOwnProperty("wishlist_id")) {
            apiCall("titlelist/addItemToList", { eisbn: _eisbn, return_session: true, list_id: context.wishlist_id }, onAddToWishlist)
        }
    }

    const onRemoveFromWishlist = (_status, _result) => {
        if (_status) {
            // setContext({ ...context, "wishlist": _result })
            apiCall("titlelist/getItems", { list_id: context.wishlist_id }, onFetchWishlist);
        }
    }

    const removeFromWishlist = (e, _eisbn) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (context.hasOwnProperty("wishlist_id")) {
            apiCall("titlelist/removeItemFromList", { eisbn: _eisbn, list_id: context.wishlist_id }, onRemoveFromWishlist)
        }
    }


    const drawRegistry = () => {
        return (
            <conditional.true value={(registry)}>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <BMAlert onClick={() => history.push("/gift-registry/" + registry)} style={{ "padding": "0px 5px", "cursor": "pointer", "textAlign": "center" }} message={<small>Click to view gift registry.</small>} type="warning" />
            </conditional.true>
        )
    }

    const getAddButton = (_item, _index) => {

        let found = (impulse) ? existsInCart(tempSession, data.eisbn, _item.code) : existsInCart(context, data.eisbn, _item.code)
        let preorder = false;
        let styles = { "padding": "4px 10px" }
        if (data.is_forthcoming) {
            if (_item.onhand < 1) {
                styles = {
                    "backgroundColor": "#e87e21",
                    "border": "none"
                }
                preorder = true;
            }
        }
        if (found) {
            return (
                <>

                    <Space size={5}>
                        <Button onClick={(e) => linkToPath(e, "/checkout/cart")} ><strong style={{ "color": "#3C8617" }}><em>{found.count} in cart</em></strong></Button>

                        {(((found && !impulse) || (found && !impulse && transitionItem !== _item.code)) &&
                            <Button style={{ "padding": "4px 13px" }} onClick={(e) => linkToPath(e, "/checkout/cart")} type="primary">Go to Cart</Button>
                        )}

                        {(((found && impulse) || (found && !impulse && transitionItem !== _item.code)) &&
                            <Button style={{ "padding": "4px 13px" }} onClick={(e) => removeItemFromCart(found)} type="primary">Remove</Button>
                        )}

                    </Space>
                    {drawRegistry()}
                </>
            )
        } else {
            return (
                <>
                    <conditional.true value={((!found) || (!found && transitionItem !== _item.code))}>
                        <Space size={5}>
                            <IncrementFormatter defaultValue={(values[data.eisbn + _item.code] ? values[data.eisbn + _item.code] : 1)} onChange={(v) => setValues({ ...values, [data.eisbn + _item.code]: v })} />
                            <Button className="preorder-hover" loading={(loading && !impulse && transitionItem === _item.code)} style={styles} onClick={(e) => addToItemToCart(e, data.eisbn, _item)} type="primary">{((loading && !impulse && transitionItem === _item.code)) ? "Adding.." : (preorder) ? "Pre-order" : "Add to cart"}</Button>
                        </Space>
                        {drawRegistry()}
                    </conditional.true>
                </>
            )
        }
    }

    const goto = (e, _path) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        history.push(_path);
    }

    const wishlistOptions = (_eisbn) => (
        <Menu>
            <Menu.Item style={{ "padding": "0px" }}><div style={{ "padding": "5px 12px" }} onClick={(e) => removeFromWishlist(e, _eisbn)}>Remove from {(!window.sitesettings.store_info.is_american) ? "favourites" : "favorites"}</div></Menu.Item>
            <Menu.Divider />
            <Menu.Item style={{ "padding": "0px" }}><div style={{ "padding": "5px 12px" }} onClick={(e) => goto(e, "/account/lists")}>Go to {(!window.sitesettings.store_info.is_american) ? "favourites" : "favorites"}</div></Menu.Item>
        </Menu>
    );

    const drawWishListButton = (_eisbn) => {
        if (existsInWishlist(context, _eisbn)) {
            return (<>
                <Dropdown
                    zIndex={(props.popup) ? 1000 : 0}
                    overlay={wishlistOptions(_eisbn)}
                    trigger={['click']}
                    arrow
                >
                    <Button style={{ "padding": "0px" }} icon={<StarIcon aria-hidden />} type="text"><small> {(!window.sitesettings.store_info.is_american) ? "Favourited" : "Favorited"}</small></Button>
                </Dropdown>
            </>
            )
        } else {
            return (<><Button style={{ "padding": "0px" }} onClick={(e) => addToWishlist(e, data.eisbn)} icon={<StarStrokeIcon aria-hidden />} type="text"><small> {(!window.sitesettings.store_info.is_american) ? "Favourite" : "Favorite"}</small></Button></>)
        }
    }

    const getWishlistButton = (_eisbn, block = true) => {
        if (!showLists || gift) {
            return (<></>)
        }

        return (
            <div style={{ "textAlign": "left" }}>
                <Divider style={{ "marginBottom": "10px" }} />
                <Space size={15} align="center">
                    <AddToList popup={props.popup} eisbn={_eisbn} />
                    {drawWishListButton(_eisbn)}
                    {(window.sitesettings.gift_registry_enabled && <GiftRegistryAdd popup={props.popup} eisbn={_eisbn} />)}
                </Space>
            </div>
        )
    }

    const drawLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let m = Modal.warn({
            icon: null,
            centered: true,
            title: <>
                <div className="shim"></div> <div className="shim"></div>
                <div style={{ "textAlign": "center", "margin": "0 auto" }}>
                    You must login or sign up to use this feature.</div>
                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                <div className="shim"></div><div className="shim"></div>
                <div style={{ "textAlign": "center", "margin": "0 auto" }}>
                    <Space size={5}><Button onClick={() => goto("/account/signup", true)} >Sign up</Button><Button onClick={() => goto("/account/login", true)}>Login</Button></Space>
                    <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                    <div className="shim"></div>
                    <Divider style={{ "margin": "0px" }} />
                </div>
            </>
            ,
            content: <>
            </>,
            okText: "Close",
        });

        const goto = (_path, _redirect = false) => {
            m.destroy();
            if (_redirect) {
                window.loginRedirect = location.pathname;
            }
            history.push(_path)
        }
    }

    const getWishlistLoginButton = (block = true) => {

        if (!showLists) {
            return (<></>)
        }

        return (<><Divider style={{ "marginBottom": "10px" }} />
            <Space>
                <Button block={block} onClick={(e) => drawLogin(e)} icon={<StarStrokeIcon aria-hidden />} type="text"><small> Add to {(!window.sitesettings.store_info.is_american) ? "favourites" : "favorites"}</small></Button>
                {(window.sitesettings.gift_registry_enabled && <Button style={{ "padding": "0px" }} onClick={(e) => drawLogin(e)} icon={<GiftOutlined aria-hidden />} type="text"><small> Registry</small></Button>)}
            </Space>
        </>)
    }

    const drawCondition = (_condition) => {
        return ("in " + _condition.toLowerCase() + " condition.");
    }

    const clearIntVal = () => {
        clearInterval(window[data.eisbn + "timout" + transitionItem]);
    }

    useEffect(clearIntVal, []);

    const showEditions = (_itm) => {
        let _term = _itm.title;
        let _authors = _itm.authors.map(item => item.name).join(" ");
        history.push("/browse/filter/k/keyword/t/" + _term + " " + _authors);
    }


    const drawLocation = (_item) => {

        const { bm_class = "", onhand = "" } = _item;

        if (!bm_class || !onhand || !window.sitesettings.bm_class_enabled) {
            return (<></>);
        }

        return (<div style={{ "color": "#3c8617", "fontSize" : "12px" }}><em>{window.sitesettings.bm_class_name}: <strong>{bm_class}</strong></em></div>)

    }


    const drawAddToCart = () => {

        let options = processCartOptions(data.available, data.price, data);
        return options.map((_item, _index) => {
            let discount = (_item.discount) ? _item.discount : false;
            if (discount === 0) {
                discount = false;
            }
            return (
                <div key={_index}>

                    <Typography.Text style={{ "fontSize": "18px", "fontWeight": "normal" }}>
                        <conditional.true value={data.available[0].availability !== "no_price"}>
                            {(_item.label && <>
                                <strong style={{ "fontSize": "24px" }}>{_item.label}
                                    {(_item.used && <em style={{ "fontSize": "14px" }}> {drawCondition(_item.used)}</em>)}
                                </strong>
                                <em style={{ "display": "block", "fontSize": "14px", "lineHeight": "16px" }}>{_item.note}</em>
                                <div className="shim"></div>
                                <div className="shim"></div>
                            </>)}
                            {(_item.strike_price && (window.sitesettings.guest_show_price || context.logged_in) && <small>List price: <s>${_item.strike_price}</s><br /></small>)}
                            <conditional.true value={(window.sitesettings.guest_show_price || context.logged_in)}>
                                <><strong style={{ "color": (_item.strike_price) ? "#ee0000" : "#000000", "fontSize": "19px" }}>{_item.price} </strong><br /></>
                            </conditional.true>
                        </conditional.true>

                        <conditional.true value={data.available[0].availability === "no_price"}>
                            <div style={{ "lineHeight": "20px" }}>Contact store for availability</div>
                            {/* <div><small>{data.available[0].sub_message}</small></div> */}
                            <div className="shim" /><div className="shim" />
                            <div style={{ "lineHeight": "14px" }}><em onClick={() => showEditions(data)} style={{ "color": "#069", "cursor": "pointer" }}><small>See <strong>all editions</strong> of {data.title}</small></em></div>
                        </conditional.true>

                        <conditional.true value={data.available[0].availability !== "no_price"}>
                            <strong style={{ color: _item.color, "fontSize": "19px" }}><Tooltip mouseEnterDelay={0.5} title={"In most cases, stock quantities are updated as soon as something changes."}>{_item.message}</Tooltip></strong> {(_item.sub_message !== "" && <div style={{ fontSize: "13px", lineHeight: "14px", color: "#333" }}> <div className="shim" /><div className="shim" />{_item.sub_message}<div className="shim" /><div className="shim" /></div>)}

                            {drawUSCanPrice(data.list_price_usa, data.list_price_can)}
                            {(discount && (window.sitesettings.guest_show_price || context.logged_in) && !window.sitesettings.hide_sale_pricing &&
                                <div style={{ "display": "block" }}><small>You save: <span style={{ "color": "#ee0000" }}>${_item.savings} ({_item.discount}%)</span> <br /></small></div>
                            )}
                        </conditional.true>

                        <conditional.true value={data.available[0].availability === "no_price"}>
                            {drawUSCanPrice(data.list_price_usa, data.list_price_can)}
                        </conditional.true>

                    </Typography.Text>
                   
                    <DrawShelfLocation available={_item} />
                    <div className="shim" /><div className="shim" />
                    <conditional.true value={(window.sitesettings.cart_enabled)}>
                        <conditional.true value={(window.sitesettings.guest_show_cart || context.logged_in)}>
                            <conditional.true value={data.available[0].availability !== "no_price"}>
                                {getAddButton(_item)}
                                {(((_item.is_extra_orderable) && (_item.onhand > 0)) && <>
                                    <div className="shim" />
                                    <div><small>More available to order</small></div>
                                </>)}
                            </conditional.true>
                            <conditional.true value={(data.available[0].availability !== "no_price" && (data.available.length > 1 && data.available.length !== _index + 1))}>
                                <Divider />
                            </conditional.true>
                        </conditional.true>
                    </conditional.true>

                    <conditional.true value={(window.sitesettings.display_carton_quantity && _item.code === "")}>
                        <div className="shim" />
                        <div style={{ "fontSize": "12px", "fontWeight": "600", "marginBottom": "-15px", "marginTop": "5px" }}>Carton quantity : {data.carton_qty}</div>
                    </conditional.true>
                </div>
            )
        })
    }

    const displayAddToCart = () => {
        if (props.summary) {


            return (
                <>
                    <conditional.true value={props.data.available[0].availability !== "no_price"}>
                        <conditional.true value={(window.sitesettings.cart_enabled)}>
                            <conditional.true value={!(!context.logged_in && !window.sitesettings.guest_show_cart)}>
                                {getAddButton(props.summary, props.summaryIndex)}
                                {(((props.summary.is_extra_orderable) && (props.summary.onhand > 0)) && <>
                                    <div className="shim" />
                                    <div><small>More available to order</small></div>
                                </>)}
                            </conditional.true>
                        </conditional.true>
                    </conditional.true>



                    <conditional.true value={context.logged_in}>
                        {/* <conditional.true value={props.data.available[0].availability !== "no_price"}> */}
                        {getWishlistButton(props.data.eisbn, false)}
                        {/* </conditional.true> */}
                    </conditional.true>
                    <conditional.true value={!context.logged_in}>
                        {getWishlistLoginButton(false)}
                    </conditional.true>
                </>
            )
        }

        return (
            <Card>
                <conditional.true value={(window.sitesettings.store_info.is_consolidated_multistore && window.sitesettings.store_info.is_multistore)}>
                    <strong style={{ "fontSize": "19px", "lineHeight": "14px" }}>{window.sitesettings.store_info.name}</strong>
                    <Divider style={{ "margin": "10px 0px" }} />
                    <div className="shim" />
                </conditional.true>
                <conditional.true value={(data.available.length === 0)}>
                    <Typography.Text style={{ "fontSize": "18px", "fontWeight": "normal" }}>
                        Unavailable
                    </Typography.Text>
                </conditional.true>

                {drawAddToCart(data.available, data.price)}

                {(data.multistore_available && <AlsoAvailable data={data.multistore_available} />)}

                <conditional.true value={context.logged_in}>
                    {/* <conditional.true value={data.available[0].availability !== "no_price"}> */}
                    {getWishlistButton(data.eisbn)}
                    {/* </conditional.true> */}
                </conditional.true>
                <conditional.true value={!context.logged_in}>
                    {getWishlistLoginButton()}
                </conditional.true>
            </Card>
        )

    }



    return displayAddToCart();

}

export default AddToCart;
