import React from "react";
import { Button, Space, Divider, Row, Col } from 'antd';
import { InfoCircleFilled, TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import gov_canada from "../media/gov_canada.svg";
import gov_canada2 from "../media/gov_canada2.svg";
import conditional from "./utilities/conditional";
import { TikTokIcon, TwitterIcon } from "../util/Icons";
import Html from "./utilities/Html";
import { getColor } from "../util/CC";
import CSS from "./utilities/CSS";
import { getOGColor } from "../util/Utils";
import GovIcon from "../media/GovIcon";

/* 
    Implemented content creator - Added fallbacks to use V1 styles.
*/

function SiteFooter2(props) {

    // Destructure for content creator


    const {
        data = {},
    } = window.sitesettings.global_content.find(item => item.component === "footer_bar") || {};

    const {
        background_colour = 0,
        text_colour = 0,
        link_colour = 0,
        link_hover_colour = 0,
        width = 900,
        terms_condition_enabled = false,
        contact_us_menu_id = "",
        font_size = 14
    } = data

    // Get template
    const template = window.sitesettings.template_info;

    // Destructure for store_info
    const { store_info = {} } = props;
    const {
        name = "",
        phone = "",
        phone_2 = "",
        toll_free = "",
        fax = "",
        email = "",
        city = "",
        address = "",
        province = "",
        postal_code = "",
        country = "",
        twitter = "",
        facebook = "",
        instagram = "",
        tiktok = "",
        url = "",
        footer_message = "",
    } = store_info;

    // Social media links in new tab
    const linkOut = (_path) => {
        window.open(_path, "_blank");
    }

    // Remove protocol & tld from website
    const formatURL = (url) => {
        url = url.replace("https://", "");
        url = url.replace("http://", "");
        url = url.replace("www.", "");
        return url;
    }

    // Create footer style
    let _class = ``;

    _class += `
    .gov_color path, .gov_color rect{
        fill: ${getColor(link_colour, template, getOGColor("search_bar_text_colour", window.sitesettings.styles.search_bar_text_colour))};
    } 
    `

    _class += `
    .bm_footer {
        background: ${getColor(background_colour, template, getOGColor("search_bar_colour", "#000"))};
        color: ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))};
        font-size : ${font_size}px;
    }`;

    _class += `
    .bm_footer a, .bm_footer .footer-btn{
        color: ${getColor(link_colour, template, getOGColor("search_bar_text_colour", "#fff"))};
        transition: color 200ms;
    }`;

    _class += `
    .bm_footer a:hover, .bm_footer .footer-btn:hover{
        color: ${getColor(link_hover_colour, template, getOGColor("search_bar_text_colour", "#fff"))};
        transition: color 200ms;
    }`;

    const Rule = (props) => {
        const { rule = "" } = props;
        if (window.sitesettings.store_info.footer_display_rules[rule]) {
            return props.children;
        } else {
            return (<></>);
        }
    }


    const getContactPagePath = () => {

        if(window.sitesettings.is_v1){
            return window.sitesettings.store_info.contact_us_url
        } else {

            // Search Nav
            let c = window.sitesettings.site_navigation.find(item => item.menu_id === contact_us_menu_id);
            if(c){ return c.path; }

            // Search Extra
            c = window.sitesettings.extra_navigation.find(item => item.menu_id === contact_us_menu_id);
            if(c){
                return c.path; 
            }
            // Return Default
            return window.sitesettings.store_info.contact_us_url;
        }
    }

    // Draw address and icon
    const drawAddress = () => {
        return (
            <div>
                <conditional.true value={(!window.sitesettings.store_info.hide_footer_address)}>
                    <div><big><strong>Find us at</strong></big></div>
                    <div className="shim" /><div className="shim" />
                    <p>
                        <Rule rule="name" ><>{name}<br /></></Rule>
                        <Rule rule="address" ><>{address}<br /></></Rule>
                        <Rule rule="city" >{city}, </Rule> <Rule rule="province" ><>{province}<br /></></Rule>
                        <Rule rule="country" ><>{country}&nbsp;</></Rule>
                        <Rule rule="postal_code" ><>{postal_code}<br /></></Rule>
                        <Link to={getContactPagePath()}><strong>Map & Hours</strong></Link>
                    </p>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                </conditional.true>
            </div>
        )
    }

    // Draw contact info if it's set
    const drawContact = () => {
        return (
            <div>
                <div><big><strong>Contact us</strong></big></div>
                <div className="shim" /><div className="shim" />
                <p>
                    <Rule rule="phone" >{(phone && <><a href={"tel:" + phone}>{phone}</a> <br /></>)}</Rule>
                    <Rule rule="phone_2" >{(phone_2 && <><a href={"tel:" + phone_2}>{phone_2}</a> <br /></>)}</Rule>
                    <Rule rule="toll_free" >{(toll_free && <>Toll Free : <a href={"tel:" + toll_free}>{toll_free}</a> <br /></>)}</Rule>
                    <Rule rule="email" >{(email && <><a href={"mailto:" + email}>{email} </a> <br /></>)}</Rule>
                    <Rule rule="url" >{(url && <><a href={url}>{formatURL(url)} </a><br /></>)}</Rule>
                    <Rule rule="fax" >{(fax && <>Fax : {fax} <br /></>)}</Rule>
                </p>
                <div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        )
    }

    // Draw social media links if set
    const drawSocial = () => {
        

        return (
            <div>
                <div><big><strong>Social</strong></big></div>
                <div className="shim" /><div className="shim" />
                <Space direction="vertical">
                    {(twitter && <Button style={{"padding" : "0px"}} aria-label="Twitter" onClick={() => linkOut(twitter)} type="text"><TwitterIcon className="footer-btn" style={{ "fontSize": "27px" }} /></Button>)}
                    {(instagram && <Button style={{"padding" : "0px"}} aria-label="Instagram" onClick={() => linkOut(instagram)} type="text"><InstagramOutlined className="footer-btn" style={{ "fontSize": "27px" }} /></Button>)}
                    {(facebook && <Button style={{"padding" : "0px"}} aria-label="Facebook" onClick={() => linkOut(facebook)} type="text"><FacebookOutlined className="footer-btn" style={{ "fontSize": "27px" }} /></Button>)}
                    {(tiktok && <Button style={{"padding" : "0px"}} aria-label="Tiktok" onClick={() => linkOut(tiktok)} type="text"><TikTokIcon className="footer-btn" style={{ "fontSize": "27px" }} /></Button>)}
                </Space>
                <div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        )
    }


    const drawCurrency = () => {
        let _cur = (window.sitesettings.store_info.is_canadian) ? "CAD" : "USD";
        return (<div style={{ "textAlign": "center" }}>Prices in {_cur}</div>);
    }

    // Draw footer message if set
    const drawFooterMessage = () => {
        if (!footer_message) {
            return (<></>);
        }
        return (
            
            <div style={{ "maxWidth": width + "px", "margin": "0 auto", "textAlign": "center", "padding" : "0px 20px" }}>
                <Rule rule="message" ><Html className="bm-anchor" clear={false} html={footer_message} /></Rule>
                <div className="shim" /><div className="shim" />
            </div>
        )
    }

    // Draw privacy policy if set
    const drawPrivacyPolicy = () => {

          // Set by Webstore Designer
          if (!window.sitesettings.is_v1) {
            if(terms_condition_enabled){
                return (
                    <div style={{ "textAlign": "center" }}>
                        <Link to="/terms-conditions">View our Terms &amp; Conditions</Link>
                    </div>
                )
            } else {
                return(<></>)
            }
        }


        if (!window.sitesettings.has_privacy_policy_page) {
            return (<></>)
        }
        return (
            <div style={{ "textAlign": "center" }}>
                <Link to="/privacy-policy">View our Privacy Policy</Link>
            </div>
        )
    }

    // Draw gov logo if set - draw bilingual version if set
    const drawGovernment = () => {
        if (!window.sitesettings.show_government_of_canada_logo) {
            return (<></>)
        }
        return (
            <div style={{ "textAlign": "center", "width" : (window.sitesettings.government_of_canada_bilingual) ? "350px" : "200px" }}>
                <GovIcon bilinual={window.sitesettings.government_of_canada_bilingual} />
            </div>
        )
    }

    // Draw bookmanager link / credit
    const drawPoweredBy = () => {
        return (
            <div className="poweredby" style={{ "textAlign": "center" }}>
                <a href="https://bookmanager.com/">
                    <span> Powered by </span><span>Bookmanager</span>
                </a>
            </div>
        )
    }


    const draw = () => {


        return (
            <footer>
                <CSS html={_class} />
                <header className="bm_footer bm-render">
                    <div style={{ "maxWidth": width + "px", "margin": "0 auto" }}>
                        <div className="bm-footer-container">
                            <div>{drawAddress()}</div>
                            <div>{drawContact()}</div>
                            {((twitter || facebook || instagram || tiktok) && <div>{drawSocial()}</div>)}
                        </div>
                    </div>
                    {drawFooterMessage()}
                    <div style={{ "opacity": "0.2" }}><div aria-hidden><Divider style={{ "borderColor": getColor(text_colour, template, "#fff"), margin: "15px 0px" }} /></div></div>
                    <div style={{ "textAlign": "center" }}>
                        <Space className="bm-footer-flex" align="center">
                            {drawPrivacyPolicy()}
                            {drawGovernment()}
                            {drawCurrency()}
                            {drawPoweredBy()}
                        </Space>
                    </div>
                    <div className="shim" /> <div className="shim" /> <div className="shim" /> <div className="shim" />
                </header>

            </footer>
        )
    }


    return draw();


    // // Front load the CSS for the componenet
    // return (
    //     <>
    //         <CSS html={_class} />
    //         <div className="bm_footer bm-render">
    //             <div style={{ "maxWidth": width + "px", "margin": "0 auto" }}>
    //                 <div className="shim" style={{ "height": "20px" }} />
    //                 <div className={"bm_footer_container"}>
    //                     <div className="bm_footer_col">
    //                         <div>
    //                             {drawAddress()}
    //                         </div>
    //                     </div>
    //                     <div className="bm_footer_col">
    //                         <div>
    //                             {drawContact()}
    //                         </div>
    //                     </div>
    //                     <div className="bm_footer_col">
    //                         <div>
    //                             {drawSocial()}
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div style={{ "textAlign": "center" }}>
    //                     {drawFooterMessage()}

    //                     <Row>
    //                         <Col span={8}>
    //                         </Col>
    //                         <Col span={8}>

    //                             {drawPrivacyPolicy()}
    //                         </Col>
    //                         <Col span={8}>
    //                             {drawCurrency()}
    //                         </Col>
    //                     </Row>

    //                     {drawGovernment()}
    //                     {drawPoweredBy()}
    //                     <br /><br />
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )

}

export default SiteFooter2; 