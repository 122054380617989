import { Form, Radio, Space } from "antd";
import React from "react";

export default function RadioField(props) {

    const { data = {}, contact={} } = props;

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        options = []
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : options[0].label;

    return (
            <Form.Item initialValue={defaultValue} extra={description} label={label} name={id} >
                <Radio.Group>
                    <Space direction="vertical">
                        {options.map(item => {
                            return (
                                <Radio value={item.label}>{item.label}</Radio>
                            )
                        })}
                    </Space>
                </Radio.Group>
            </Form.Item>
    )
}