import { Checkbox, Form, Space } from "antd";
import React from "react";

export default function CheckBoxField(props) {

    const { data = {},  contact = {} } = props;

    const {
        id = "",
        label = "Checkbox",
        type = "checkbox",
        description = "",
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? true : false;

    return (
        <Form.Item initialValue={defaultValue} label={null} valuePropName="checked" extra={description} name={id} >
            <Checkbox >{label}</Checkbox>
        </Form.Item>
    )
}